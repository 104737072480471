<template>
  <BaseListPage locale-section="pages.signa" route="signum">
    <SignaTree :selected-signum1="$route.params.signum1" />
  </BaseListPage>
</template>

<script>
export default {
  name: "InscriptionssPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    SignaTree: () => import("./SignaTree")
  }
};
</script>
